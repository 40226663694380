import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Copy, HorizontalRule, Flex, Box, Button } from '@elparking/components'

const RegisterButton = ({ onClick }) => (
  <Flex flexWrap="wrap" mt="8px">
    <Box width="100%">
      <HorizontalRule />
    </Box>
    <Box width="100%" mb="16px">
      <Copy padding="0" size="medium" fontWeight="book">
        <FormattedMessage defaultMessage="¿No tienes cuenta?" />
      </Copy>
    </Box>
    <Box width="100%">
      <Button.bordered
        lineHeight="1.4em"
        dataTest="register-button"
        width="100%"
        onClick={onClick}
      >
        <FormattedMessage defaultMessage="Regístrate" />
      </Button.bordered>
    </Box>
  </Flex>
)
RegisterButton.propTypes = {
  onClick: PropTypes.func,
}

export default RegisterButton
