import React from 'react'
import styled from 'styled-components'
import {
  themeFontWeight,
  themeColorProp,
  Button,
  Image,
} from '@elparking/components'

const iconSrc =
  'data:image/svg+xml,%3Csvg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"%3E%3CclipPath id="a"%3E%3Cpath d="M44.666 19.668h-20.5v8.5h11.8c-1.1 5.4-5.7 8.5-11.8 8.5-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4c-3.9-3.4-8.9-5.5-14.5-5.5-12.2 0-22 9.8-22 22s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23a)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23a)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23a)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23a)" fill="%234285F4" d="M48.083 47.834l-31-24-4-3 35-10z"/%3E%3C/svg%3E'

const SocialButtonContainer = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-weight: ${themeFontWeight('book')};
  border: 1px solid ${themeColorProp('color')};

  &:hover {
    color: ${themeColorProp('color')};
    background: ${themeColorProp('hoverBackground')};
  }

  &:disabled {
    opacity: 0.4;
  }

  &:hover:disabled {
    border: 1px solid ${themeColorProp('color')};
  }
`

const SocialButtonLabel = styled.span`
  margin-left: 10px;
  color: ${themeColorProp('white')};
`

const SocialButtonGoogle = (props) => (
  <SocialButtonContainer
    type="button"
    color="gray"
    border="gray"
    backgroundColor="white"
    width="100%"
    {...props}
  >
    <Image mb={0} alt="Google" src={iconSrc} height="16px" />
    <SocialButtonLabel>Google</SocialButtonLabel>
  </SocialButtonContainer>
)

export default SocialButtonGoogle
