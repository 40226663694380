import React from 'react'
import Form from './Login'
import { useConfig } from '@elparking/components'

const Login = (props) => {
  const googleRecapchaSitekey = useConfig('googleRecapchaSitekey')
  return <Form sitekey={googleRecapchaSitekey} {...props} />
}

export default Login
