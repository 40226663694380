import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { Control } from '../Input'
import { required, length } from '../../validators'

import messages from './messages'

const PasswordField = ({ intl, ...restProps }) => (
    <Control
      model='.password'
      type='password'
      validators={{
          required: required(),
          length: length({ min: 6, max: 100 }),
      }}
      placeholder={intl.formatMessage(messages.placeholder)}
      errorMessages={{
          required: intl.formatMessage(messages.requiredError),
          length: intl.formatMessage(messages.lengthError),
      }}
      debounce={250}
      {...restProps}
    />
)

PasswordField.propTypes = {
    intl: PropTypes.object,
}

export default injectIntl(PasswordField)
