// Sync validators
export const checked =
  () =>
  (val = false) =>
    val
export const required =
  () =>
  (val = '') =>
    !!val && val.trim().length > 0
export const length =
  ({ min, max }) =>
  (val = '') =>
    !!val && val.length >= min && val.length <= max
export const phone =
  () =>
  (val = '') =>
    /^(\+[0-9]+[-. ]*)?(\([0-9]+\)[-. ]*)?([0-9][0-9-. ]+[0-9])$/.test(val)
const HTMLEntities = '\\u00A0-\\u9999<>\'"\\&%'
export const email =
  () =>
  (val = '') =>
    new RegExp(
      '^[^' +
        HTMLEntities +
        ']+@[^' +
        HTMLEntities +
        ']+\\.[^' +
        HTMLEntities +
        ']+$',
      'i'
    ).test(val)

// Async validators
export const available =
  ({ isAvailable }) =>
  (val = '', done) =>
    isAvailable(val).then(({ result: exists }) => done(!exists))

export const password =
  () =>
  (val = '') => {
    return (
      !!/[A-Z]+/.test(val) &&
      !!/[a-z]+/.test(val) &&
      !!/[0-9]+/.test(val) &&
      val.length >= 8 &&
      val.length < 100
    )
  }
