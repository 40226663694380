import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Control } from '../Input'
import messages from './messages'
import { required as requiredValidator } from '../../validators'

const LastNameField = ({ intl, required, ...restProps }) => (
    <Control
      model='.lastName'
      validators={required
        ? { required: requiredValidator() }
        : null
      }
      placeholder={intl.formatMessage(messages.placeholder)}
      autoComplete='off'
      errorMessages={required
        ? { required: intl.formatMessage(messages.requiredError) }
        : null
      }
      debounce={250}
      {...restProps}
    />
)

LastNameField.propTypes = {
    intl: PropTypes.object,
    required: PropTypes.bool.isRequired,
}

LastNameField.defaultProps = {
    required: false,
}

export default injectIntl(LastNameField)
