import React, { useState } from 'react'
import RegisterForm from './RegisterForm'
import { useConfig } from '@elparking/components'

const Register = (props) => {
  const countries = useConfig('countries')
  const defaultCountry = useConfig('defaultCountry')
  const tradename = useConfig('tradename')
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry)

  return (
    <RegisterForm
      tradename={tradename}
      acceptedCountries={countries}
      defaultCountry={defaultCountry}
      setSelectedCountry={setSelectedCountry}
      selectedCountry={selectedCountry}
      {...props}
    />
  )
}

export default Register
