import React from 'react'
import PropTypes from 'prop-types'
import { Box, Modal } from '@elparking/components'
import LoginModalContent from './LoginModalContent'

const modalStyles = {
  content: {
    top: '0%',
    left: '0',
    right: '0',
    bottom: '0',
    marginRight: '0',
    overflow: 'auto',
    padding: '0',
    border: 'none',
    background: 'transparent',
    minHeight: '100%',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 9999,
  },
}

const LoginModal = ({
  isOpen,
  onClose,
  registerFormComponent,
  showCloseButton,
  ...restProps
}) => {
  return (
    <Modal
      style={modalStyles}
      label=" "
      isShown={isOpen}
      onClose={onClose}
      showCloseButton={showCloseButton}
      showModalTitle={false}
      width="384px"
      padding="20px 32px 32px 32px"
    >
      <Box pt="12px">
        <LoginModalContent
          registerFormComponent={registerFormComponent}
          {...restProps}
        />
      </Box>
    </Modal>
  )
}

LoginModal.defaultProps = {
  isOpen: false,
  showCloseButton: true,
  onClose: () => {},
}

LoginModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
  registerFormComponent: PropTypes.func,
}

export default LoginModal
