import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { HorizontalRule, Copy, Flex, Box, Button } from '@elparking/components'

const LoginButton = ({ onClick }) => (
  <Flex flexWrap="wrap" mt="16px">
    <Box width="100%" mb="16px">
      <HorizontalRule />
    </Box>
    <Box width="100%" mb="16px">
      <Copy padding="0" size="medium" fontWeight="book">
        <FormattedMessage defaultMessage="¿Ya tienes cuenta?" />
      </Copy>
    </Box>
    <Box width="100%">
      <Button.bordered
        dataTest="login-button"
        width="100%"
        onClick={onClick}
        lineHeight="1.4em"
      >
        <FormattedMessage defaultMessage="Inicia sesión" />
      </Button.bordered>
    </Box>
  </Flex>
)

LoginButton.propTypes = {
  onClick: PropTypes.func,
}

export default LoginButton
