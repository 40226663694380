import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Copy, Image } from '@elparking/components'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Control } from '../Dropdown'
import { required } from '../../validators'
import messages from './messages'
import * as flags from './Flags'


function mapCountryOptions (countries) {
    return countries.map((country) => ({
        country,
        value: country.iso3,
        label: country.name,
    }))
}

function renderCountryOption (option) {
    const country = option.country.iso3.toUpperCase()
    return (
        <Flex alignItems='center'>
            <Image src={flags[country]} alt={country} />
            <Box>
                <Copy padding='0 0 0 10px' size='small' fontWeight='regular'>{option.label}</Copy>
            </Box>
        </Flex>
    )
}

const CountryField = ({ intl, tradename, countries, defaultCountry = {}, ...restProps }) => {
    return (<Control
      key={defaultCountry.iso3}
      model='.countryIso3'
      validators={{
          required: required(),
      }}
      errorMessages={{
          required: intl.formatMessage(messages.requiredError),
      }}
      // Dropdown props
      defaultValue={defaultCountry.iso3}
      options={mapCountryOptions(countries)}
      renderSelected={renderCountryOption}
      renderOption={renderCountryOption}
      renderPlaceholder={() => <FormattedMessage
        defaultMessage='¿Dónde usarás {tradename}?'
        values={{
          tradename,
        }}/>}
      onlyDesktop
      {...restProps}
    />)
}

CountryField.propTypes = {
    intl: PropTypes.object,
    countries: PropTypes.array,
    defaultCountry: PropTypes.object,
    tradename: PropTypes.string,
}

export default injectIntl(CountryField)
