import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  CodeInput,
  Box,
  Flex,
  Copy,
  Button,
  LoaderButton,
} from '@elparking/components'
import { FormattedMessage } from 'react-intl'
import {
  TWO_FACTOR_AUTH_INCORRECT_STATUS,
  TWO_FACTOR_AUTH_EXPIRED_STATUS,
} from '../constants'

export const CODE_SIZE = 6

const TwoFAForm = ({
  loading,
  userEmail,
  onRequestNewCode,
  onSendCode,
  errorStatus,
}) => {
  const [otpCode, setOtpCode] = useState('')
  const isDisabledSubmit = otpCode.length !== CODE_SIZE || loading

  const getErrorStatusMessage = (errorStatus) => {
    let errorMessage = ''
    switch (errorStatus) {
      case TWO_FACTOR_AUTH_INCORRECT_STATUS:
        errorMessage = (
          <FormattedMessage defaultMessage="El código es incorrecto, vuelva a intentarlo." />
        )
        break
      case TWO_FACTOR_AUTH_EXPIRED_STATUS:
        errorMessage = (
          <FormattedMessage defaultMessage="El código ya no es válido, solicita uno nuevo." />
        )
        break
      default:
        errorMessage = <FormattedMessage defaultMessage="Error desconocido" />
        break
    }

    if (!errorMessage) {
      return
    }

    return (
      errorMessage && (
        <Copy
          data-test="error-message-otp"
          padding="0"
          fontColor="red"
          fontSize="small"
          fontWeight="bold"
        >
          {errorMessage}
        </Copy>
      )
    )
  }

  return (
    <Flex
      height="100%"
      width="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box width="100%" marginBottom="32px" textAlign="center">
          <Copy fontSize="xxLarge" fontWeight="bold" fontColor="gray">
            <FormattedMessage defaultMessage="Introduce el código" />
          </Copy>
          <Copy
            wordBreak="break-word"
            marginTop="16px"
            fontSize="medium"
            fontWeight="regular"
            fontColor="gray"
          >
            <FormattedMessage
              defaultMessage="Hemos enviado a {userEmail} un correo con el código de verificación."
              values={{
                userEmail: <strong>{userEmail}</strong>,
              }}
            />
          </Copy>
        </Box>
        <Box width="100%" textAlign="center">
          <CodeInput
            size={CODE_SIZE}
            type="number"
            fontSize="xxLarge"
            fontWeight="bold"
            spaceBetween="8px"
            height="48px"
            width="40px"
            groupBy={3}
            onChange={setOtpCode}
          />
        </Box>
        <Button.unstyled
          data-test="request-new-otp-button"
          width="100%"
          marginTop="48px"
          onClick={onRequestNewCode}
        >
          <Copy
            padding="0"
            fontSize="medium"
            fontWeight="medium"
            textAlign="center"
            fontColor={loading ? 'gray' : 'main'}
          >
            <FormattedMessage defaultMessage="Solicitar nuevo código" />
          </Copy>
        </Button.unstyled>
        {errorStatus && (
          <Box marginTop="24px" width="100%">
            <Alert
              padding="12px"
              type="error"
              borderColor="red"
              borderWidth="1px"
              borderStyle="solid"
              borderRadius="2px"
            >
              {getErrorStatusMessage(errorStatus)}
            </Alert>
          </Box>
        )}
      </Flex>
      <Box width="100%" marginTop="96px">
        <LoaderButton
          data-test="send-otp-button"
          width="100%"
          height="48px"
          disabled={isDisabledSubmit}
          backgroundColor={isDisabledSubmit ? 'main20' : 'main'}
          loading={loading}
          onClick={() => onSendCode(otpCode)}
        >
          <FormattedMessage defaultMessage="Siguiente" />
        </LoaderButton>
      </Box>
    </Flex>
  )
}

TwoFAForm.propTypes = {
  loading: PropTypes.bool,
  userEmail: PropTypes.string,
  onRequestNewCode: PropTypes.func,
  onSendCode: PropTypes.func,
  errorStatus: PropTypes.number,
}

export default TwoFAForm
