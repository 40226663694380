import React from 'react'
import PropTypes from 'prop-types'
import { Control, Errors, actionTypes } from 'react-redux-form'
import { Input, FieldError, ErrorsWrapper } from '@elparking/components'

const mapProps = {
  valid: ({ fieldValue: field }) =>
    !field.touched || (!field.validating && field.valid),
  changed: ({ fieldValue: field }) => field.touched,
  customChange: ({ change }) => change,
  defaultValue: ({ fieldValue: field }) => field.value,
  errors: ({ fieldValue: field }) => field.errors,
  forceFocus: ({ fieldValue }) => {
    const { intents } = fieldValue
    if (!Array.isArray(intents) || !intents.length) {
      return false
    }
    return intents.some((intent) => intent.type === actionTypes.FOCUS)
  },
}

const ControlField = ({
  model,
  errorMessages,
  showErrorOptions,
  errorColor,
  autoComplete,
  ...restProps
}) => {
  return (
    <div>
      <Control.text
        autoComplete={autoComplete}
        component={Input}
        model={model}
        mapProps={mapProps}
        errorColor={errorColor}
        {...restProps}
      />
      {errorMessages && (
        <Errors
          wrapper={ErrorsWrapper}
          component={({ children, fieldValue }) => (
            <FieldError errors={fieldValue.errors} errorColor={errorColor}>
              {children}
            </FieldError>
          )}
          model={model}
          messages={errorMessages}
          show={showErrorOptions}
        />
      )}
    </div>
  )
}

ControlField.propTypes = {
  model: PropTypes.string.isRequired,
  validators: PropTypes.object,
  showErrorOptions: PropTypes.any,
  errorMessages: PropTypes.object,
  errorColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  autoComplete: PropTypes.string,
}

ControlField.defaultProps = {
  errorColor: 'red',
  autoComplete: 'off',
  showErrorOptions: { touched: true },
}
export default ControlField
