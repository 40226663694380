import React from 'react'
import PropTypes from 'prop-types'
import findCountryBy from '../util/findCountryBy'

function buildTermsAndConditionsPath(
  countries,
  selectedCountry,
  termsAndConditionsPath
) {
  const country = findCountryBy(countries, { iso3: selectedCountry })

  if (!country) {
    return termsAndConditionsPath()
  }

  return termsAndConditionsPath(
    null,
    country.defaultLanguage.code,
    country.iso3.toLowerCase()
  )
}

const TermsLink = ({
  countries,
  selectedCountry,
  children,
  termsAndConditionsPath,
}) => (
  <a
    rel="noopener noreferrer"
    href={buildTermsAndConditionsPath(
      countries,
      selectedCountry,
      termsAndConditionsPath
    )}
    target="_blank"
  >
    {children}
  </a>
)

TermsLink.propTypes = {
  children: PropTypes.node.isRequired,
  countries: PropTypes.array,
  selectedCountry: PropTypes.string,
  termsAndConditionsPath: PropTypes.func.isRequired,
}

TermsLink.defaultProps = {
  countries: [],
  termsAndConditionsPath: () => {},
}

export default TermsLink
