import React from 'react'
import PropTypes from 'prop-types'
import { Control, Errors } from 'react-redux-form'
import { Checkbox, FieldError, ErrorsWrapper } from '@elparking/components'

const mapProps = {
  valid: ({ fieldValue }) => fieldValue.valid,
  changed: ({ fieldValue }) => fieldValue.touched,
}

const ControlField = ({
  model,
  errorMessages,
  showErrorOptions,
  errorColor,
  ...restProps
}) => {
  return (
    <div>
      <Control.checkbox
        component={Checkbox}
        model={model}
        mapProps={mapProps}
        errorColor={errorColor}
        {...restProps}
      />
      {errorMessages && (
        <Errors
          wrapper={ErrorsWrapper}
          component={({ children }) => (
            <FieldError errorColor={errorColor}>{children}</FieldError>
          )}
          model={model}
          messages={errorMessages}
          show={showErrorOptions}
        />
      )}
    </div>
  )
}

ControlField.propTypes = {
  model: PropTypes.string.isRequired,
  validators: PropTypes.object,
  showErrorOptions: PropTypes.object,
  errorMessages: PropTypes.object,
  errorColor: PropTypes.string,
}

ControlField.defaultProps = {
  errorColor: 'red',
  showErrorOptions: { touched: true },
}
export default ControlField
