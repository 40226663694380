import React from 'react'
import PropTypes from 'prop-types'
import { ReCaptcha } from '@elparking/components'
import isCountryError from './isCountryError'

class Login extends React.Component {
  static propTypes = {
    login: PropTypes.func,
    socialLogin: PropTypes.func,
    onLogin: PropTypes.func,
    renderForm: PropTypes.func,
    renderCountryForm: PropTypes.func,
    sitekey: PropTypes.string,
    reCaptcha: PropTypes.string,
  }

  static defaultProps = {
    renderForm: () => null,
    renderCountryForm: () => null,
    onLogin: () => {},
    reCaptcha: null,
    sitekey: '',
  }

  state = {
    error: {},
    loading: false,
    socialParams: {},
    reCaptcha: this.props.reCaptcha,
    reCaptchaKey: 0,
    loadingCaptcha: true,
    params: {},
    start2faAuth: 0,
  }

  onSubmitSocial = this.onSubmitSocial.bind(this)
  onSubmitCountryModal = this.onSubmitCountryModal.bind(this)
  onSubmit = this.onSubmit.bind(this)
  onLoginSuccess = this.onLoginSuccess.bind(this)
  onReceiveError = this.onReceiveError.bind(this)
  verifyCallback = this.verifyCallback.bind(this)
  renderReCaptcha = this.renderReCaptcha.bind(this)

  verifyCallback(reCaptcha) {
    this.setState({
      reCaptcha,
      loadingCaptcha: false,
      params: {
        ...this.state.params,
        reCaptcha,
      },
    })
  }

  onLoginSuccess({ error, result }) {
    if (error) {
      this.setState({ loading: false, error })
    } else {
      this.props.onLogin(result)
    }
  }

  onReceiveError() {
    this.setState(() => ({
      loading: false,
      reCaptcha: null,
    }))
  }

  refreshReCaptcha() {
    const { reCaptchaKey } = this.state
    this.setState({ reCaptchaKey: reCaptchaKey + 1, loadingCaptcha: true })
  }

  refreshReCaptcha = this.refreshReCaptcha.bind(this)

  onSubmit(params) {
    const { reCaptcha } = this.state
    this.setState(
      () => ({
        loading: true,
        error: {},
        params: {
          reCaptcha,
          ...params,
        },
        start2faAuth: Date.now(),
      }),
      () => {
        this.refreshReCaptcha()
      }
    )
  }

  onSubmitSocial(params) {
    this.setState({ loading: true, error: {}, socialParams: params })

    this.props
      .socialLogin(params)
      .then(this.onLoginSuccess, this.onReceiveError)
  }

  onSubmitCountryModal(params) {
    this.setState({ loading: true, error: {} })

    this.props
      .socialLogin({ ...this.state.socialParams, ...params })
      .then(this.onLoginSuccess, this.onReceiveError)
  }

  renderReCaptcha() {
    return (
      <ReCaptcha
        key={`ReCaptchaLogin_${this.state.reCaptchaKey}`}
        sitekey={this.props.sitekey}
        action="login"
        callback={this.verifyCallback}
      />
    )
  }

  render() {
    const { renderForm, renderCountryForm, login } = this.props
    const { error, loading, loadingCaptcha, params, start2faAuth } = this.state

    if (error && isCountryError(error)) {
      return renderCountryForm({
        onSubmit: this.onSubmitCountryModal,
      })
    }

    return renderForm({
      onSubmitSocial: this.onSubmitSocial,
      onSubmit: this.onSubmit,
      error,
      // backward compatibility
      errors: [error],
      loading,
      disabled: loadingCaptcha,
      refreshReCaptcha: this.refreshReCaptcha,
      renderReCaptcha: this.renderReCaptcha,
      request: login,
      start2faAuth,
      params,
      onLoginSuccess: this.onLoginSuccess,
      onReceiveError: this.onReceiveError,
    })
  }
}

export default Login
