import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, useConfig } from '@elparking/components'
import { navigateTo, reload } from '@elparking/utils'
import Login from '../Login'
import Register from '../Register'
import LoginForm from '../LoginForm'
import RegisterForm from '../RegisterForm'
import LoginButton from './LoginButton'
import RegisterButton from './RegisterButton'

const LOGIN_VIEW = 'login'
const REGISTER_VIEW = 'register'

const LoginModalContent = ({
  trackLoginSuccessEvent,
  trackSignupEvent,
  login,
  socialLogin,
  register,
  redirect,
  views,
  registerFormComponent: RegisterFormComponent,
  promoCode,
  logo,
  countryFormElement: CountryForm,
  homePath,
  requestPasswordPath,
  termsAndConditionsPath,
  hidePromoCode,
  application,
}) => {
  const [view, setView] = useState(LOGIN_VIEW)
  const facebookAppId = useConfig('facebookAppId')
  const googleAppId = useConfig('googleAppId')

  return (
    <Box>
      <Box display={view === LOGIN_VIEW ? 'block' : 'none'}>
        <Login
          login={login}
          socialLogin={socialLogin}
          onLogin={() => {
            trackLoginSuccessEvent()
            redirect ? navigateTo(redirect) : reload()
          }}
          renderForm={(props) =>
            views.includes(LOGIN_VIEW) ? (
              <Box>
                <LoginForm
                  homePath={homePath}
                  requestPasswordPath={requestPasswordPath}
                  facebookId={facebookAppId}
                  googleId={googleAppId}
                  logo={logo}
                  application={application}
                  {...props}
                />
                {views.includes(REGISTER_VIEW) && (
                  <RegisterButton onClick={() => setView(REGISTER_VIEW)} />
                )}
              </Box>
            ) : null
          }
          renderCountryForm={(props) =>
            views.includes(LOGIN_VIEW) ? (
              <Box>
                <CountryForm {...props} />
              </Box>
            ) : null
          }
        />
      </Box>
      <Box display={view === REGISTER_VIEW ? 'block' : 'none'}>
        <Register
          register={register}
          onRegister={() => {
            trackSignupEvent()
            redirect ? navigateTo(redirect) : reload()
          }}
          renderForm={(props) =>
            views.includes(REGISTER_VIEW) ? (
              <Box>
                <RegisterFormComponent
                  homePath={homePath}
                  termsAndConditionsPath={termsAndConditionsPath}
                  hidePromoCode={hidePromoCode}
                  logo={logo}
                  initialState={{ promoCode }}
                  {...props}
                />
                {views.includes(LOGIN_VIEW) && (
                  <LoginButton onClick={() => setView(LOGIN_VIEW)} />
                )}
              </Box>
            ) : null
          }
        />
      </Box>
    </Box>
  )
}

LoginModalContent.propTypes = {
  login: PropTypes.func.isRequired,
  logo: PropTypes.node,
  hidePromoCode: PropTypes.bool,
  socialLogin: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  trackLoginSuccessEvent: PropTypes.func,
  trackSignupEvent: PropTypes.func,
  registerFormComponent: PropTypes.func,
  redirect: PropTypes.string,
  promoCode: PropTypes.string,
  application: PropTypes.string,
  countryFormElement: PropTypes.func,
  initialView: PropTypes.oneOf([LOGIN_VIEW, REGISTER_VIEW]),
  homePath: PropTypes.string.isRequired,
  requestPasswordPath: PropTypes.string.isRequired,
  termsAndConditionsPath: PropTypes.func.isRequired,
  views: PropTypes.arrayOf(PropTypes.oneOf([LOGIN_VIEW, REGISTER_VIEW])),
}

LoginModalContent.defaultProps = {
  registerFormComponent: RegisterForm,
  initialView: LOGIN_VIEW,
  trackLoginSuccessEvent: () => {},
  trackSignupEvent: () => {},
  countryFormElement: null,
  hidePromoCode: false,
  promoCode: null,
  views: [LOGIN_VIEW, REGISTER_VIEW],
}

export default LoginModalContent
