import React from 'react'
import PropTypes from 'prop-types'
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'

class Register extends React.Component {
  static propTypes = {
    register: PropTypes.any.isRequired,
    onRegister: PropTypes.func,
    renderForm: PropTypes.func.isRequired,
    sitekey: PropTypes.string.isRequired,
    reCaptcha: PropTypes.string,
  }

  static defaultProps = {
    onRegister: () => null,
    reCaptcha: null,
  }

  state = {
    errors: null,
    loading: false,
    reCaptcha: this.props.reCaptcha,
    reCaptchaKey: 0,
  }

  onRegisterSuccess = this.onRegisterSuccess.bind(this)
  onReceiveError = this.onReceiveError.bind(this)
  onSubmit = this.onSubmit.bind(this)

  componentDidMount() {
    this.loadReCaptcha()
  }

  onRegisterSuccess({ error, result }) {
    if (error) {
      return this.onReceiveError(error)
    }

    this.props.onRegister(result)
  }

  onReceiveError(errors) {
    this.setState(({ reCaptchaKey }) => ({
      loading: false,
      errors,
      reCaptcha: null,
      reCaptchaKey: reCaptchaKey + 1,
    }))
  }

  loadReCaptcha() {
    const { sitekey } = this.props
    loadReCaptcha && loadReCaptcha(sitekey)
  }

  loadReCaptcha = this.loadReCaptcha.bind(this)

  onSubmit(params) {
    this.setState({ loading: true })
    const { register } = this.props
    const { reCaptcha } = this.state
    register({
      reCaptcha,
      ...params,
    }).then(this.onRegisterSuccess, this.onReceiveError)
  }

  verifyCallback(reCaptcha) {
    this.setState({ reCaptcha })
  }
  verifyCallback = this.verifyCallback.bind(this)

  renderReCaptcha() {
    return (
      <ReCaptcha
        key={`ReCaptcha_${this.state.reCaptchaKey}`}
        sitekey={this.props.sitekey}
        action="register"
        verifyCallback={this.verifyCallback}
      />
    )
  }

  renderReCaptcha = this.renderReCaptcha.bind(this)

  render() {
    const { errors, loading } = this.state
    const { renderForm } = this.props
    return renderForm({
      onSubmit: this.onSubmit,
      errors: errors ? [errors] : [],
      loading: loading,
      renderReCaptcha: this.renderReCaptcha,
    })
  }
}

export default Register
