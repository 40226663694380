import React from 'react'
import PropTypes from 'prop-types'
import { LoaderButton, Flex, Box, Copy } from '@elparking/components'
import { FormattedMessage } from 'react-intl'
import {
  LocalForm,
  actions,
  FirstNameField,
  LastNameField,
  EmailField,
  PasswordFieldStrict,
  PromoCodeField,
  CountryField,
  TermsField,
  FieldGroup,
} from '@elparking/form'
import TermsLink from '../TermsLink'
import findCountryBy from '../util/findCountryBy'

class RegisterForm extends React.Component {
  static propTypes = {
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string,
      })
    ),
    initialState: PropTypes.shape({
      promoCode: PropTypes.string,
    }),
    logo: PropTypes.node,
    loading: PropTypes.bool,
    selectedCountry: PropTypes.object,
    defaultCountry: PropTypes.shape({
      iso3: PropTypes.string,
    }),
    acceptedCountries: PropTypes.array,
    setSelectedCountry: PropTypes.func,
    onSubmit: PropTypes.func,
    getSupportedCountries: PropTypes.func,
    renderReCaptcha: PropTypes.func,
    termsAndConditionsPath: PropTypes.func.isRequired,
    hidePromoCode: PropTypes.bool.isRequired,
    tradename: PropTypes.string.isRequired,
  }

  static defaultProps = {
    errors: [],
    onSubmit: () => {},
    renderReCaptcha: () => {},
  }

  state = {
    selectedCountry: {},
    defaultCountry: this.props.defaultCountry.iso3,
  }

  componentDidUpdate(_, prevState) {
    if (prevState.defaultCountry.iso3 !== this.state.defaultCountry.iso3) {
      this.formDispatch(
        actions.change('registration.country', this.state.defaultCountry.iso3)
      )
    }

    if (prevState.selectedCountry !== this.state.selectedCountry) {
      this.formDispatch(actions.reset('registration.acceptConditions'))
    }
  }

  render() {
    const {
      tradename,
      hidePromoCode,
      acceptedCountries,
      onSubmit,
      initialState,
      selectedCountry,
      setSelectedCountry,
      loading,
      logo,
      defaultCountry,
      termsAndConditionsPath,
      errors,
      renderReCaptcha,
    } = this.props

    const defaultState = { ...initialState }
    if (acceptedCountries.length === 1) {
      defaultState.countryIso3 = defaultCountry.iso3
    }

    return (
      <LocalForm
        model="registration"
        onChange={({ countryIso3 }) =>
          setSelectedCountry(
            findCountryBy(acceptedCountries, { iso3: countryIso3 })
          )
        }
        onSubmit={(values) => {
          const country = findCountryBy(acceptedCountries, {
            iso3: values.countryIso3,
          })
          onSubmit({
            ...values,
            country: country.iso2,
          })
        }}
        initialState={(values) => ({
          ...defaultState,
          ...values,
        })}
        getDispatch={(formDispatch) => (this.formDispatch = formDispatch)}
      >
        <Flex flexWrap="wrap">
          <Flex w="100%" p="10px 0 5px 0" justifyContent="center" mb="35px">
            {logo}
          </Flex>
          <Box mb="16px">
            <Copy padding="0" size="medium" fontWeight="book">
              <FormattedMessage defaultMessage="Rellena el formulario para el registro" />
            </Copy>
          </Box>
          {errors.length > 0 && (
            <Box mb="8px" w="100%">
              <Copy padding="0" color="red" size="small" fontWeight="regular">
                {errors.map(({ message }) => message).join(',')}
              </Copy>
            </Box>
          )}
          <FieldGroup disabled={loading}>
            <Box w="100%" flex="1 0 100%" mb="8px">
              <FirstNameField dataTest="register-first-name-field" />
            </Box>
            <Box w="100%" flex="1 0 100%" mb="8px">
              <LastNameField dataTest="register-last-name-field" />
            </Box>
            <Box w="100%" flex="1 0 100%" mb="8px">
              <EmailField dataTest="register-email-field" />
            </Box>
            <Box w="100%" flex="1 0 100%" mb="8px">
              <PasswordFieldStrict
                dataTest="register-password-field"
                autoComplete="new-password"
              />
            </Box>
            <Box w="100%" flex="1 0 100%" mb="16px">
              {acceptedCountries.length > 1 && (
                <CountryField
                  tradename={tradename}
                  countries={acceptedCountries}
                  defaultCountry={defaultCountry}
                />
              )}
            </Box>
            <Box w="100%" flex="1 0 100%" mb="16px">
              {!hidePromoCode && (
                <PromoCodeField
                  tradename={tradename}
                  validateIcon={false}
                  showExpanded={!!initialState.promoCode}
                />
              )}
            </Box>
            <Box w="100%" flex="1 0 100%" mb="16px">
              <TermsField
                id="terms"
                model=".terms"
                dataTest="register-terms-field"
                renderTermsLink={(props) => {
                  return (
                    <TermsLink
                      {...props}
                      termsAndConditionsPath={termsAndConditionsPath}
                      countries={acceptedCountries}
                      selectedCountry={selectedCountry.iso3}
                    >
                      <FormattedMessage defaultMessage="términos y condiciones" />
                    </TermsLink>
                  )
                }}
              />
            </Box>
          </FieldGroup>
          <LoaderButton
            dataTest="register-submit-button"
            formNoValidate
            loading={loading}
            model="registration"
            width="100%"
          >
            <FormattedMessage defaultMessage="Crear cuenta" />
          </LoaderButton>
          <Box>{renderReCaptcha()}</Box>
        </Flex>
      </LocalForm>
    )
  }
}

export default RegisterForm
