import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {HorizontalRule, LoaderButton, Flex, Box, Copy, Icon} from '@elparking/components';
import {FormattedMessage} from 'react-intl';
import FacebookSocialButton from '../FacebookSocialButton';
import GoogleSocialButton from '../GoogleSocialButton';
import TwoFaModal from '../TwoFaModal';
import {FieldGroup, EmailField, PasswordField, LocalForm} from '@elparking/form';
import FacebookLoginButton from '../Login/FacebookLoginButton';
import GoogleLoginButton, {
  isGoogleInitializationError,
  isGoogleLoginAttemptError,
} from '../Login/GoogleLoginButton';
import RememberMeField from './RememberMeField';

function canUseGoogleLogin({error}) {
  return !isGoogleInitializationError(error);
}

const LoginForm = ({
  error,
  loading,
  facebookId,
  googleId,
  renderReCaptcha,
  refreshReCaptcha,
  disabled,
  facebookLoginButtonComponent: FacebookLoginButtonComponent,
  googleLoginButtonComponent: GoogleLoginButtonComponent,
  captureMessage,
  requestPasswordPath,
  onSubmitSocial,
  onSubmit,
  application,
  logo,
  request,
  start2faAuth,
  onLoginSuccess,
  onReceiveError,
  params,
}) => {
  const [googleError, setGoogleError] = useState(null);
  useEffect(() => {
    if (googleError !== null) {
      captureMessage(`[GoogleLogin] ${googleError.code} ${googleError.message}`);
    }
  }, [googleError, captureMessage]);
  return (
    <LocalForm
      model='login'
      onSubmit={onSubmit}
      initialState={{
        email: '',
        password: '',
        rememberMe: false,
      }}
    >
      <Box position='relative'>
        <Flex flexWrap='wrap'>
          <Flex w='100%' p='10px 0 5px 0' justifyContent='center' mb='35px'>
            {logo}
          </Flex>
          <Box mb='16px'>
            <Copy padding='0' size='medium' fontWeight='book'>
              <FormattedMessage defaultMessage='Inicia sesión para continuar' />
            </Copy>
          </Box>
            {document.querySelector("[data-expired='expired']") && (
                <Box mb="8px" width="100%">
                    <Copy padding="0" color="red" size="small" fontWeight="book">
                        <FormattedMessage defaultMessage="El enlace que has pinchado ha caducado. Por favor inicia sesión para ver el contenido" />
                    </Copy>
                </Box>
            )}
          {error && (
            <Box mb='8px' width='100%'>
              <Copy padding='0' color='red' size='small' fontWeight='regular'>
                {error.message}
              </Copy>
            </Box>
          )}
          {isGoogleLoginAttemptError(googleError) && (
            <Box mb='8px'>
              <Copy
                data-test='error-message'
                padding='0'
                color='red'
                size='small'
                fontWeight='regular'
              >
                <FormattedMessage defaultMessage='No hemos podido iniciar sesión con Google' />
              </Copy>
            </Box>
          )}
          <FieldGroup disabled={loading}>
            <Box w='100%' flex='1 0 100%' mb='8px'>
              <EmailField
                disable={loading || disabled}
                dataTest='login-email-field'
                leftIcon={<Icon color='gray' type='user' />}
              />
            </Box>
            <Box w='100%' flex='1 0 100%' mb='8px'>
              <PasswordField
                disable={loading || disabled}
                dataTest='login-password-field'
                leftIcon={<Icon color='gray' type='password' />}
              />
            </Box>
            <Box w='100%' flex='1 0 100%' my='24px'>
              <RememberMeField
                id='remember-me'
                dataTest='login-remember-me-field'
                label={
                  <Copy padding='0' size='xSmall' fontWeight='regular'>
                    <FormattedMessage defaultMessage='Recordarme en este equipo' />
                  </Copy>
                }
              />
            </Box>
          </FieldGroup>
          <LoaderButton
            dataTest='login-submit-button'
            formNoValidate
            loading={loading}
            model='login'
            width='100%'
          >
            <FormattedMessage defaultMessage='Inicia sesión' />
          </LoaderButton>
          <Box w='100%'>
            <Copy padding='8px 0 24px 0' align='center'>
              <Copy
                as='a'
                href={requestPasswordPath}
                className='js-link-forgot-password'
                color='main'
                size='xSmall'
                fontWeight='book'
              >
                <FormattedMessage defaultMessage='¿Has olvidado tu contraseña?' />
              </Copy>
            </Copy>
          </Box>
          <Flex w='100%' alignItems='center' mb='8px'>
            <Box flex='1'>
              <HorizontalRule />
            </Box>
            <Box px='8px'>
              <Copy padding='0' fontWeight='book' size='xSmall' color='gray60'>
                <FormattedMessage defaultMessage='Accede rápidamente con' />
              </Copy>
            </Box>
            <Box flex='1'>
              <HorizontalRule />
            </Box>
          </Flex>
          <Flex w='100%' justifyContent='space-between' alignItems='center'>
            <Box flex='1' mr='8px'>
              <FacebookLoginButtonComponent
                facebookId={facebookId}
                onLogin={onSubmitSocial}
                application={application}
                render={(onClick) => (
                  <FacebookSocialButton
                    className='js-facebook-login'
                    disabled={loading}
                    onClick={onClick}
                  />
                )}
              />
            </Box>
            <Box flex='1' ml='8px'>
              <GoogleLoginButtonComponent
                googleId={googleId}
                onLogin={onSubmitSocial}
                onError={setGoogleError}
                application={application}
                render={(onClick) => (
                  <GoogleSocialButton
                    className='js-google-login'
                    disabled={
                      loading ||
                      !canUseGoogleLogin({
                        error: googleError,
                      })
                    }
                    onClick={onClick}
                  />
                )}
              />
            </Box>
          </Flex>
        </Flex>
        <Box>{renderReCaptcha()}</Box>
        <TwoFaModal
          logo={logo}
          request={request}
          start2faAuth={start2faAuth}
          params={params}
          onSuccess={onLoginSuccess}
          onConnectionError={onReceiveError}
          userEmail={(params && params.email) || ' '}
          isCaptchaLoading={disabled}
          refreshReCaptcha={refreshReCaptcha}
        />
      </Box>
    </LocalForm>
  );
};

LoginForm.propTypes = {
  facebookId: PropTypes.string,
  googleId: PropTypes.string,
  facebookLoginButtonComponent: PropTypes.elementType,
  googleLoginButtonComponent: PropTypes.elementType,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  captureMessage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onSubmitSocial: PropTypes.func,
  requestPasswordPath: PropTypes.string.isRequired,
  logo: PropTypes.node,
  application: PropTypes.string,
  renderReCaptcha: PropTypes.func,
  refreshReCaptcha: PropTypes.func,
  request: PropTypes.func.isRequired,
  start2faAuth: PropTypes.number.isRequired,
  params: PropTypes.object,
  onLoginSuccess: PropTypes.func,
  onReceiveError: PropTypes.func,
};

LoginForm.defaultProps = {
  // eslint-disable-next-line react/display-name
  renderReCaptcha: () => <div />,
  refreshReCaptcha: () => {},
  onSubmit: () => {},
  onSubmitSocial: () => {},
  captureMessage: () => {},
  requestPasswordPath: '',
  errors: {},
  disabled: true,
  start2faAuth: 0,
  loading: false,
  facebookLoginButtonComponent: FacebookLoginButton,
  googleLoginButtonComponent: GoogleLoginButton,
  logo: null,
};

export default LoginForm;
