import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Button, Copy, Icon, DropdownMenu, Hide } from '@elparking/components'
import { useApi } from '@elparking/utils'

const MenuButton = ({ user }) => (
  <Copy fontSize="medium" color="inherit" fontWeight="book">
    {user} <Icon type="arrow-down" color="inherit" />
  </Copy>
)

MenuButton.propTypes = {
  user: PropTypes.string,
}

const LoginButton = ({
  getUser,
  color,
  fontWeight,
  fontSize,
  loginModalComponent: LoginModalComponent,
  onShowModal,
  userMenu,
  userHomePath,
  mobileMenu,
  mobileMenuWithoutLogin,
  buttonComponent : ButtonComponent,
  logo,
  ...props
}) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (show && onShowModal) {
      onShowModal()
    }
  }, [show, onShowModal])

  const {
    response: { result: user, error: userError },
    loading: loadingUser,
    sendRequest: getUserRequest,
  } = useApi(getUser)

  useEffect(() => {
    !loadingUser && !user && !userError && getUserRequest()
  }, [loadingUser, user, userError, getUserRequest])

  if (user) {
    return (
      <DropdownMenu
        desktopMenu={{
          renderButtonValue: <MenuButton user={user.firstName} />,
          subItems: userMenu,
        }}
        mobileMenu={[
          mobileMenu,
          {
            title: user.firstName,
            subtitle: <FormattedMessage defaultMessage="Ver perfil" />,
            url: userHomePath,
            active: false,
            subItems: userMenu,
          },
        ]}
        logo={logo}
      />
    )
  }

  return (
    <>
      <Hide md lg>
        <DropdownMenu
          desktopMenu={{
            renderButtonValue: <></>,
            subItems: [],
          }}
          mobileMenu={[
            ...mobileMenuWithoutLogin,
            {
              title: (
                <ButtonComponent
                  disabled={loadingUser || user === null}
                  dataTest="show-login-modal-button"
                  type='button'
                  fontSize={fontSize}
                  fontWeight={fontWeight}
                  color={color}
                  width={['100%', 'auto']}
                  onClick={() => setShow(true)}
                >
                <FormattedMessage defaultMessage="Iniciar sesión" />
                <LoginModalComponent
                  isOpen={show}
                  onClose={() => setShow(false)}
                  logo={logo}
                  {...props}
                />
              </ButtonComponent>
              ),
              subtitle: null,
              url: null,
              active: false,
              subItems: [],
            }
          ]}
          logo={logo}
        />
      </Hide>
      <Hide xs sm>
        <ButtonComponent
          disabled={loadingUser || user === null}
          dataTest="show-login-modal-button"
          type='button'
          fontSize={fontSize}
          fontWeight={fontWeight}
          color={color}
          width={['100%', 'auto']}
          onClick={() => setShow(true)}
        >
          <FormattedMessage defaultMessage="Iniciar sesión" />
          <LoginModalComponent
            isOpen={show}
            onClose={() => setShow(false)}
            logo={logo}
            {...props}
          />
        </ButtonComponent>
      </Hide>
    </>
  )
}

LoginButton.propTypes = {
  loginModalComponent: PropTypes.elementType,
  onShowModal: PropTypes.func,
  color: PropTypes.any,
  fontSize: PropTypes.any,
  fontWeight: PropTypes.any,
  getUser: PropTypes.func,
  userMenu: PropTypes.array,
  mobileMenu: PropTypes.shape({
    title: PropTypes.node,
    url: PropTypes.string,
    active: PropTypes.bool,
    subItems: PropTypes.array,
  }),
  mobileMenuWithoutLogin: PropTypes.array,
  userHomePath: PropTypes.string,
  buttonComponent: PropTypes.elementType,
  logo: PropTypes.node
}

LoginButton.defaultProps = {
  onShowModal: () => {},
  color: 'gray60',
  fontSize: 'large',
  fontWeight: 'book',
  buttonComponent: Button
}

export default LoginButton
