import React from 'react'
import PropTypes from 'prop-types'
import FacebookDefaultLoginComponent from 'react-facebook-login/dist/facebook-login-render-props'
import { useConfig } from '@elparking/components'

const FacebookLoginButton = ({
  facebookId,
  render,
  onLogin,
  application,
  facebookLoginComponent: FacebookLoginComponent,
}) => {
  const facebookNetworkName = useConfig('facebookNetworkName')
  return (
    <FacebookLoginComponent
      disableMobileRedirect
      appId={facebookId}
      fields="email"
      responseType="code"
      callback={({ accessToken, email }) =>
        onLogin({
          network: facebookNetworkName,
          accessToken,
          email,
          application,
        })
      }
      render={(renderFacebookProps) => render(renderFacebookProps.onClick)}
    />
  )
}

FacebookLoginButton.propTypes = {
  facebookId: PropTypes.any,
  onLogin: PropTypes.func,
  application: PropTypes.string,
  render: PropTypes.func,
  facebookLoginComponent: PropTypes.elementType,
}

FacebookLoginButton.defaultProps = {
  render: () => null,
  facebookId: null,
  facebookLoginComponent: FacebookDefaultLoginComponent,
}

export default FacebookLoginButton
