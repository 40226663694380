import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useApi } from '@elparking/utils'
import { Modal, Box } from '@elparking/components'
import TwoFAForm from '../TwoFAForm'
import { OTP_HEADER_NAME, TWO_FACTOR_AUTH_REQUIRED_STATUS } from '../constants'

TwoFaModal.propTypes = {
  userEmail: PropTypes.string,
  request: PropTypes.func,
  params: PropTypes.object,
  onSuccess: PropTypes.func,
  onConnectionError: PropTypes.func,
  start2faAuth: PropTypes.number,
  isCaptchaLoading: PropTypes.bool,
  refreshReCaptcha: PropTypes.func,
}

function TwoFaModal({
  start2faAuth,
  request,
  params,
  onSuccess,
  onConnectionError,
  userEmail,
  isCaptchaLoading,
  refreshReCaptcha,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [errorStatus, setErrorStatus] = useState(null)
  const [otpCode, setOtpCode] = useState(false)
  const [regenerateOtpCode, setRegenerateOtpCode] = useState(!!start2faAuth)

  const { response, loading, error, resetResponse, sendRequest } =
    useApi(request)

  const closeModal = useCallback(() => {
    if (!modalIsOpen) {
      return
    }
    setOtpCode(false)
    setRegenerateOtpCode(null)
    setErrorStatus(null)
    resetResponse()
    refreshReCaptcha()
    setModalIsOpen(false)
  }, [modalIsOpen, resetResponse, refreshReCaptcha])

  // Fire request otp
  useEffect(() => {
    if (start2faAuth) {
      setRegenerateOtpCode(true)
    }
  }, [start2faAuth])

  // Fire request otp
  useEffect(() => {
    if (regenerateOtpCode) {
      sendRequest(params)
      setErrorStatus(null)
      setRegenerateOtpCode(null)
    }
  }, [regenerateOtpCode, params, sendRequest])

  // handle success request
  useEffect(() => {
    if (response.result && !response.error) {
      onSuccess({ result: response.result })
      closeModal()
    }
  }, [response, closeModal, onSuccess])

  // handled error (API and validation)
  useEffect(() => {
    if (response.error) {
      if (response.error.status) {
        if (response.error.status === TWO_FACTOR_AUTH_REQUIRED_STATUS) {
          setModalIsOpen(true)
        } else {
          setErrorStatus(response.error.status)
        }
        refreshReCaptcha()
        return
      }
      onSuccess({ error: response.error })
      closeModal()
    }
  }, [response.error, closeModal, refreshReCaptcha, onSuccess])

  // Send request with OPT code
  useEffect(() => {
    if (otpCode && !isCaptchaLoading) {
      const headers = { [OTP_HEADER_NAME]: otpCode }
      sendRequest(params, headers)
      setErrorStatus(null)
      setOtpCode(null)
    }
  }, [otpCode, params, isCaptchaLoading, sendRequest, resetResponse])

  // unhandled error. Ej .: connection problem
  useEffect(() => {
    if (error) {
      onConnectionError(error)
      closeModal()
    }
  }, [error, closeModal, onConnectionError])

  return (
    <Modal
      isShown={modalIsOpen}
      onClose={() => {
        onSuccess({ error: {} })
        closeModal()
      }}
      label=""
      showModalTitle={false}
      width="384px"
      height="624px"
      padding="32px"
    >
      <Box
        pt={['24px', '0px']}
        backgroundColor="white"
        maxWidth={['100%', '384px', '384px']}
        height={['calc(100vh - 68px)', '564px']}
      >
        <TwoFAForm
          key={'form_' + regenerateOtpCode}
          userEmail={userEmail}
          onRequestNewCode={() => setRegenerateOtpCode(new Date())}
          onSendCode={(otpCode) => setOtpCode(otpCode)}
          errorStatus={errorStatus}
          loading={loading}
        />
      </Box>
    </Modal>
  )
}

export default TwoFaModal
