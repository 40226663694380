import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { Control } from '../Input'
import { required, email } from '../../validators'

import messages from './messages'

const EmailField = ({ intl, ...restProps }) => (
  <Control
    model=".email"
    type="email"
    validators={{
      required: required(),
      email: email(),
    }}
    placeholder={intl.formatMessage(messages.placeholder)}
    autoComplete="email"
    errorMessages={{
      required: intl.formatMessage(messages.requiredError),
      email: intl.formatMessage(messages.emailError),
    }}
    debounce={250}
    {...restProps}
  />
)

EmailField.propTypes = {
  intl: PropTypes.object,
}

export default injectIntl(EmailField)
