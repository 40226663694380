import React from 'react'
import styled from 'styled-components'
import {
  themeFontWeight,
  themeColorProp,
  Button,
  Image,
} from '@elparking/components'

const iconSrc =
  'data:image/svg+xml,%3Csvg viewBox="0 0 271 270" preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M255.08 1.618H17.646c-8.137 0-14.73 6.594-14.73 14.73v237.434c0 8.135 6.594 14.73 14.73 14.73h127.826V165.157h-34.781v-40.28h34.781V95.172c0-34.473 21.055-53.244 51.807-53.244 14.73 0 27.391 1.097 31.08 1.587v36.026l-21.328.01c-16.725 0-19.963 7.947-19.963 19.609v25.717h39.887l-5.193 40.28h-34.693v103.355h68.012c8.135 0 14.73-6.596 14.73-14.73V16.348c-.001-8.137-6.596-14.73-14.731-14.73z" fill="%23fff"/%3E%3C/svg%3E'

const SocialButtonContainer = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-weight: ${themeFontWeight('book')};
  background: ${themeColorProp('background')};
  color: ${themeColorProp('color')};
  border: 1px solid ${themeColorProp('color')};

  &:hover {
    color: ${themeColorProp('color')};
    background: ${themeColorProp('hoverBackground')};
  }

  &:disabled {
    background: ${themeColorProp('background')};
  }
`

const SocialButtonLabel = styled.span`
  margin-left: 10px;
`

const FacebookSocialButton = (props) => (
  <SocialButtonContainer
    type="button"
    color="white"
    border="facebookBlue"
    backgroundColor="facebookBlue"
    hoverBackground="darkFacebookBlue"
    width="100%"
    {...props}
  >
    <Image alt="Facebook" mb={0} src={iconSrc} height="16px" />
    <SocialButtonLabel>Facebook</SocialButtonLabel>
  </SocialButtonContainer>
)

export default FacebookSocialButton
