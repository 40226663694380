import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Copy } from '@elparking/components'
import { Control } from '../Checkbox'
import { checked } from '../../validators'
import messages from './messages'

const TermsField = ({ intl, renderTermsLink, ...restProps }) => (
  <Control
    model=".acceptConditions"
    validators={{
      checked: checked(),
    }}
    errorMessages={{
      checked: intl.formatMessage(messages.requiredError),
    }}
    label={
      <Copy padding="0" fontWeight="regular" size="xxSmall">
        <FormattedMessage
          defaultMessage="He leído y acepto los {termsAndConditions}"
          values={{
            termsAndConditions: renderTermsLink(),
          }}
        />
      </Copy>
    }
    {...restProps}
  />
)

TermsField.propTypes = {
  intl: PropTypes.object,
  renderTermsLink: PropTypes.func,
}

TermsField.defaultProps = {
  renderTermsLink: () => null,
}

export default injectIntl(TermsField)
