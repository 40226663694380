import React from 'react'
import PropTypes from 'prop-types'
import GoogleLoginDefaultComponent from 'react-google-login'
import { once } from '@elparking/utils'
import { useConfig } from '@elparking/components'

export const GOOGLE_INITIALIZATION_FAILED_ERROR =
  'GOOGLE_INITIALIZATION_FAILED_ERROR'
export const GOOGLE_LOGIN_ATTEMPT_ERROR = 'GOOGLE_LOGIN_ATTEMPT_ERROR'
export const GOOGLE_LOGIN_POPUP_CLOSED = 'GOOGLE_LOGIN_POPUP_CLOSED'
export const GOOGLEPLUS_NAME = 'google-el-parking'

export function isGoogleInitializationError(error) {
  return error && error.type === GOOGLE_INITIALIZATION_FAILED_ERROR
}

export function isGoogleLoginAttemptError(error) {
  return error && error.type === GOOGLE_LOGIN_ATTEMPT_ERROR
}

function mapGoogleError({ error, details }) {
  if (error === 'idpiframe_initialization_failed') {
    return {
      type: GOOGLE_INITIALIZATION_FAILED_ERROR,
      message: details,
      code: error,
    }
  }

  if (error === 'popup_closed_by_user') {
    return {
      type: GOOGLE_LOGIN_POPUP_CLOSED,
      message: 'The user closed the popup before finishing the sign in flow',
      code: error,
    }
  }

  return {
    type: GOOGLE_LOGIN_ATTEMPT_ERROR,
    message: details,
    code: error,
  }
}

const GoogleLoginButton = ({
  googleId,
  render,
  onLogin,
  onError,
  application,
  googleLoginComponent: GoogleLoginComponent,
}) => {
  const googleNetworkName = useConfig('googleNetworkName')
  return (
    <GoogleLoginComponent
      fetchBasicProfile
      accessType="offline"
      responseType="code"
      onSuccess={({ code }) => {
        window.gapi.auth2.getAuthInstance().currentUser.listen(
          once((user) => {
            onLogin({
              network: googleNetworkName,
              code,
              email: user.getBasicProfile().getEmail(),
              application,
            })
          })
        )
      }}
      onFailure={(error) => onError(mapGoogleError(error))}
      clientId={googleId}
      render={(renderGoogleProps) => render(renderGoogleProps.onClick)}
    />
  )
}

GoogleLoginButton.propTypes = {
  googleId: PropTypes.any,
  onLogin: PropTypes.func,
  onError: PropTypes.func,
  render: PropTypes.func,
  application: PropTypes.string,
  googleLoginComponent: PropTypes.elementType,
}

GoogleLoginButton.defaultProps = {
  render: () => null,
  googleId: null,
  googleLoginComponent: GoogleLoginDefaultComponent,
}

export default GoogleLoginButton
