const BRA = 'data:image/svg+xml,%3Csvg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"%3E%3Cdefs%3E%3Ccircle id="a" cx="12" cy="12" r="12"/%3E%3Ccircle id="c" cx="6.147" cy="6" r="6"/%3E%3C/defs%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cmask id="b" fill="%23fff"%3E%3Cuse xlink:href="%23a"/%3E%3C/mask%3E%3Cuse fill="%23009C36" fill-rule="nonzero" xlink:href="%23a"/%3E%3Cg mask="url(%23b)"%3E%3Cpath fill="%23FFDF00" d="M-1.2 12l13.282-9L25.2 12l-13.281 9z"/%3E%3Cg transform="translate(5.853 6)"%3E%3Cmask id="d" fill="%23fff"%3E%3Cuse xlink:href="%23c"/%3E%3C/mask%3E%3Cuse fill="%23002778" fill-rule="nonzero" xlink:href="%23c"/%3E%3Cpath fill="%23FFF" mask="url(%23d)" transform="rotate(15 6.71 5.568)" d="M-.873 4.984h15.167v1.167H-.873z"/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E'
const COL = 'data:image/svg+xml,%3Csvg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cg fill="none"%3E%3Cpath d="m0 12c0-6.627375 5.372625-12 12-12s12 5.372625 12 12l-12 1.0434844z" fill="%23ffda44"/%3E%3Cpath d="m1.60575 18c2.07492187 3.5866875 5.95260937 6 10.39425 6 4.4416406 0 8.3193281-2.4133125 10.39425-6l-10.39425-.782625z" fill="%23d80027"/%3E%3Cpath d="m22.39425 18c1.0210312-1.7650312 1.60575-3.8141719 1.60575-6h-24c0 2.1858281.58471875 4.2349688 1.60575 6z" fill="%230052b4"/%3E%3C/g%3E%3C/svg%3E'
const CRI = 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24"%3E%3Cg fill="none"%3E%3Ccircle cx="12" cy="12" r="12" fill="%23FFF"/%3E%3Cpath fill="%23D80027" d="M23.254 7.826H.746A11.973 11.973 0 0 0 0 12c0 1.468.264 2.874.746 4.174h22.508c.482-1.3.746-2.706.746-4.174 0-1.468-.264-2.874-.746-4.174z"/%3E%3Cpath d="M12 0a11.972 11.972 0 0 0-9.096 4.174h18.193A11.972 11.972 0 0 0 12.001 0zm9.097 19.826H2.904A11.972 11.972 0 0 0 12.001 24c3.637 0 6.896-1.618 9.096-4.174z" fill="%230052B4"/%3E%3C/g%3E%3C/svg%3E'
const ECU = 'data:image/svg+xml,%3Csvg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cg fill="none"%3E%3Cpath d="m0 12c0-6.627375 5.372625-12 12-12s12 5.372625 12 12l-12 1.0434844z" fill="%23ffda44"/%3E%3Cpath d="m1.60575 18c2.07492187 3.5866875 5.95260937 6 10.39425 6 4.4416406 0 8.3193281-2.4133125 10.39425-6l-10.39425-.782625z" fill="%23d80027"/%3E%3Cpath d="m22.39425 18c1.0210312-1.7650312 1.60575-3.8141719 1.60575-6h-24c0 2.1858281.58471875 4.2349688 1.60575 6z" fill="%230052b4"/%3E%3Ccircle cx="12" cy="12" fill="%23ffda44" r="4.173891"/%3E%3Cpath d="m12 14.6086875c-1.4384531 0-2.6086875-1.1702813-2.6086875-2.6086875v-1.5652031c0-1.43845315 1.1702813-2.60868753 2.6086875-2.60868753s2.6086875 1.17028126 2.6086875 2.60868753v1.5652031c0 1.4384531-1.1702344 2.6086875-2.6086875 2.6086875z" fill="%23338af3"/%3E%3Cpath d="m16.1738906 5.73914063h-3.1304531c0-.57628125-.4672031-1.04348438-1.0434844-1.04348438-.5762812 0-1.0434843.46720313-1.0434843 1.04348438h-3.13045318c0 .57632812.50198438 1.04348437 1.07821875 1.04348437h-.03473437c0 .57632812.46715625 1.04348437 1.04348437 1.04348437 0 .57632813.46715623 1.04348438 1.04348433 1.04348438h2.0869688c.5763281 0 1.0434844-.46715625 1.0434844-1.04348438.5763281 0 1.0434844-.46715625 1.0434844-1.04348437h-.0347344c.5762812 0 1.0782187-.46720312 1.0782187-1.04348437z" fill="%23000"/%3E%3C/g%3E%3C/svg%3E'
const ESP = 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24"%3E%3Cg fill="none"%3E%3Cpath fill="%23FFDA44" d="M0 11.88c0 1.452.264 2.842.746 4.128L12 17.04l11.254-1.032c.482-1.286.746-2.676.746-4.128s-.264-2.842-.746-4.128L12 6.72.746 7.752A11.727 11.727 0 0 0 0 11.88z"/%3E%3Cpath d="M23.237 7.826C21.544 3.256 17.152 0 12 0S2.456 3.257.763 7.826h22.474zM.763 16.174C2.456 20.744 6.848 24 12 24s9.544-3.257 11.237-7.826H.763z" fill="%23D80027"/%3E%3C/g%3E%3C/svg%3E'
const FRA = 'data:image/svg+xml,%3Csvg height="24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cg fill="none" transform="matrix(0 -1 1 0 -.000001 23.999998)"%3E%3Cpath d="m0 11.88c0 1.452.264 2.842.746 4.128l11.254 1.032 11.254-1.032c.482-1.286.746-2.676.746-4.128s-.264-2.842-.746-4.128l-11.254-1.032-11.254 1.032c-.495 1.32-.747 2.718-.746 4.128z" fill="%23fff"/%3E%3Cpath d="m23.237 7.826c-1.693-4.57-6.085-7.826-11.237-7.826s-9.544 3.257-11.237 7.826z" fill="%23003dd8"/%3E%3Cpath d="m.751 16.129c1.693 4.57 6.085 7.826 11.237 7.826s9.544-3.257 11.237-7.826z" fill="%23d80000"/%3E%3C/g%3E%3C/svg%3E'
const USA = 'data:image/svg+xml,%3Csvg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"%3E%3Cdefs%3E%3Ccircle id="a" cx="12" cy="12" r="12"/%3E%3C/defs%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cmask id="b" fill="%23fff"%3E%3Cuse xlink:href="%23a"/%3E%3C/mask%3E%3Cuse fill="%23F0F0F0" fill-rule="nonzero" xlink:href="%23a"/%3E%3Cg mask="url(%23b)" fill="%23D80027" fill-rule="nonzero"%3E%3Cpath d="M11.28 12.236H24c0-1.061-.147-2.09-.42-3.068h-12.3v3.068zM11.28 6.099h10.93a11.972 11.972 0 0 0-2.812-3.069H11.28v3.069zM11.81 24c2.869 0 5.506-.957 7.588-2.557H4.22A12.417 12.417 0 0 0 11.81 24zM1.408 18.374h20.803c.599-.943 1.063-1.975 1.369-3.069H.039c.306 1.094.77 2.126 1.37 3.069z"/%3E%3C/g%3E%3Cpath d="M5.559 1.874h1.093l-1.017.739.389 1.196-1.018-.74-1.017.74.336-1.033c-.896.746-1.68 1.62-2.328 2.594h.35l-.647.47c-.1.168-.197.34-.29.513l.31.951-.578-.419C1 7.19.868 7.5.75 7.817l.34 1.048h1.258l-1.017.74.388 1.195-1.017-.739-.61.443c-.06.49-.172 1.26-.172 1.767H12V0C9.63 0 7.42.688 5.559 1.874zm.465 8.926l-1.018-.739-1.017.739.389-1.196-1.017-.739h1.257l.388-1.195.389 1.195h1.257l-1.017.74.389 1.195zm-.389-4.691l.389 1.195-1.018-.739-1.017.74.389-1.196-1.017-.74h1.257l.388-1.195.389 1.196h1.257l-1.017.739zm4.693 4.691l-1.017-.739-1.017.739.388-1.196-1.017-.739h1.257l.389-1.195.388 1.195h1.258l-1.018.74.389 1.195zm-.389-4.691l.389 1.195-1.017-.739-1.017.74.388-1.196-1.017-.74h1.257l.389-1.195.388 1.196h1.258l-1.018.739zm0-3.496l.389 1.196-1.017-.74-1.017.74.388-1.196-1.017-.739h1.257L9.311.678l.388 1.196h1.258l-1.018.739z" fill="%230052B4" fill-rule="nonzero" mask="url(%23b)"/%3E%3C/g%3E%3C/svg%3E'

export {
    BRA,
    COL,
    CRI,
    ECU,
    ESP,
    FRA,
    USA,
}
