import React from 'react'

import { Checkbox } from '@elparking/form'

const RememberMeField = (props) => (
    <Checkbox
      model='.rememberMe'
      {...props}
    />
)

export default RememberMeField
